import React from 'react'
import Lottie from 'react-lottie'
import { AnimatedIcon } from './AnimatedShape.styles'

const AnimatedShape = ({ animation, width, height, ...pos }) => (
  <AnimatedIcon {...pos}>
    <Lottie
      width={width}
      height={height}
      options={{
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }}
    />
  </AnimatedIcon>
)

export default AnimatedShape
