import IconLogo from './IconLogo.json'
import IconTriangle from './IconTriangle.json'
import RealTimePreview from './RealTimePreview.json'
import RenderingSpeed from './RenderingSpeed.json'
import CreativeLibrary from './CreativeLibrary.json'
import OneClickUpload from './OneClickUpload.json'

export {
  IconLogo,
  IconTriangle,
  RealTimePreview,
  RenderingSpeed,
  CreativeLibrary,
  OneClickUpload
}
