const getQuery = (apolloClient, pageQuery) =>
  apolloClient
    .query(pageQuery)
    .then(({ data }) => {
      return data
    })
    .catch((e) => {
      console.log('[GraphQL SRR Query Error]', e)
      return { data: null }
    })

export default getQuery
