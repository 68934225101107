import { styled } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

export const AnimatedIcon = styled(Box)(
  ({
    left = 'auto',
    right = 'auto',
    top = 'auto',
    bottom = 'auto',
    x = '0',
    y = '0',
    position = 'absolute'
  }) => ({
    transform: `translate(${x}, ${y})`,
    position: position,
    left: left,
    right: right,
    bottom: bottom,
    top: top
  })
)
